import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


export const constantRoutes = [
    {
        path: "/index",
        component: () => import("../views/home/index.vue"),
        hidden: true
    },
    {
        path: "/",
        redirect: "/index"
    },
    {
        path: '/monitoring',
        name:'monitoring',
        component: () => import('../views/monitoring/index.vue'),
    },

    {
        path: '/video-playback',
        name:'video-playback',
        component: () => import('../views/videoPlayBack/index.vue'),
    },
]




const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: [...constantRoutes,]
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
